import "./styles/main.scss";
import "slick-carousel";
import $ from 'jquery';

import datepickerFactory from 'jquery-datepicker';
import datepickerJAFactory from 'jquery-datepicker/i18n/jquery.ui.datepicker-pl';

datepickerFactory($);
datepickerJAFactory($);

$('.toggle-favourite').on('click', function(e) {
  e.preventDefault();

  $('#schowek_tlo').toggle();
})

$('.company__gallery').slick({
  prevArrow: '<button type="button" class="slick-prev"></button>',
  nextArrow: '<button type="button" class="slick-next"></button>'
});

$('.company__contacts a').on('click', function(e) {
  if($(this).closest('.dots').length) {
    e.preventDefault();
  }
})

$('.company__contacts .btn').on('click', function() {
  $(this)
    .closest('div')
    .find('.dots')
    .removeClass('dots');
  $(this).remove();
})

$('.menu-toggle').on('click', function() {
  $('.main-header .user-panel').slideToggle();
});

$(function() {
  $('.date-picker').datepicker();
  $.datepicker.regional['ja'];
});

$('.toggle-search').on('click', function() {
  $('.search-pro').slideToggle();
})

$('.toggle-contact').on('click', function() {
  $('.company__contact').slideToggle();
})

$('.search-more-criteria span').on('click', function() {
  $('.search-pro__checks').toggleClass('open');
})

let mobileViewport = window.matchMedia('screen and (max-width: 1200px)');

if(mobileViewport.matches) {
  setView('mobile');
} else {
  setView('desktop');
}

mobileViewport.addListener(function(mq) {
  if(mq.matches) {
    setView('mobile');
  } else {
    setView('desktop');
  }
});

function setView(type) {
  if(type === 'mobile') {
    $('.company__aside').insertAfter('.company__gallery');
    $('.listing aside').insertAfter('.toggle-map');
  } else {
    $('.company__aside').insertAfter('.company__main');
    $('.listing aside').insertAfter('main');
  }
}